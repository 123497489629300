<style lang="scss" scoped>
</style>
<template>
  <el-dialog
    :title="!props.isEdit ? '新增分類' : '編輯分類'"
    v-model="state.dialogFormVisible"
    width="800px"
  >
    <el-form
      :model="state.form"
      :ref="(el) => (state.addFrom = el)"
      :rules="state.addRules"
    >
      <div class="flex_line_c">
        <el-row class="width_100">
          <el-col :span="24">
            <el-form-item
              label="分類名稱"
              :label-width="state.formLabelWidth"
              prop="name"
              class="width_100"
            >
              <el-input
                v-model="state.form.name"
                autocomplete="off"
                placeholder="請輸入分類名稱"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="!props.isEdit">
            <el-form-item
              label="父級分類"
              :label-width="state.formLabelWidth"
              prop="integral"
              class="width_100"
            >
              <el-cascader
                v-model="state.form.classify"
                :options="state.allSorts"
                :props="{ expandTrigger: 'hover', checkStrictly: true }"
                clearable
                class="width_100"
                popper-class="popper"
              ></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="狀態"
              prop="region"
              class="width_100"
              :label-width="state.formLabelWidth"
            >
              <el-select placeholder="請選擇" v-model="state.form.region">
                <el-option label="正常" value="1"></el-option>
                <el-option label="禁用" value="0"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col
            :span="24"
            class="flex_m"
            v-if="!props.isEdit && !state.form.classify"
          >
            <el-radio v-model="state.radio" label="1">普通分類</el-radio>
            <el-radio v-model="state.radio" label="2"
              >必選分類（如湯底）</el-radio
            >
            <el-radio v-model="state.radio" label="3"
              >自選分類（必需選擇前兩個分類中的一個
              裏面的商品,才能進行下單）</el-radio
            >
          </el-col>
        </el-row>
      </div>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="state.dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="add_submit">確 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { reactive, watch, getCurrentInstance } from "vue";

export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    integral: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      default: "",
    },
    isEditPass: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: String,
      default: "",
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    toggleDialogVisible(data) {
      return data;
    },
    reloadData(data) {
      return data;
    },
  },
  setup(props, { emit }) {
    // 变量

    const proxy = getCurrentInstance().appContext.config.globalProperties;
    const { $message, $http } = proxy;

    const getAllSORTS = async () => {
      const res = await $http.getAllGoodsSort();
      if (res.status !== 200) return;
      state.allSorts = [];
      Object.values(res.data).forEach((item) => {
        const obj = {
          value: item.id,
          label: item.sort_name,
          sort_type:item.sort_type
        };
        state.allSorts.push(obj);
      });
    };

    // state
    var state = reactive({
      radio: "1", // 選擇分類單選
      dialogFormVisible: props.dialogVisible,
      form: {
        name: props.name,
        classify: "", // 多級選擇的值
        region: "1",
      },
      addFrom: "",
      formLabelWidth: "80px",
      addRules: {
        name: [{ required: true, message: "請輸入分類名稱", trigger: "blur" }],
        region: [{ required: true, message: "請選擇狀態", trigger: "blur" }],
      },
      allSorts: [], // 所有父級分類
    });

    // 监听
    watch(
      () => props.dialogVisible,
      (val, old) => {
        if (val) (state.dialogFormVisible = val), getAllSORTS();
      }
    );

    watch(
      () => state.dialogFormVisible,
      (val, old) => {
        if (!val) emit("toggleDialogVisible", true);
      }
    );

    watch(
      () => props.name,
      (val, old) => {
        state.form.name = val;
      }
    );

    watch(
      () => props.integral,
      (val, old) => {
        state.form.integral = val;
      }
    );

    // 生命周期
    // created

    // methods

    const add_submit = () => {
      // 新增分類
      let sort_type = null;
      if (state.form.classify.length) {
        state.allSorts.forEach((item) => {
          if (item.value == state.form.classify[0]) {
            sort_type = item.sort_type;
          }
        });
      }

      state.addFrom.validate(async (valid) => {
        if (!valid) return; // 新增拿到的数据
        if (!props.isEdit) {
          const res = await $http.addGoodsSort({
            sort_name: state.form.name,
            parent: state.form.classify ? state.form.classify[0] : 0,
            sort_type: sort_type ? sort_type : state.radio,
            status: state.form.region,
          });
          if (res.status !== 200) return;
          $message.success("新增成功");
          state.form.name = "";
          state.form.region = "1";
        } else {
          const res = await $http.editGoodsSort({
            sort_name: state.form.name,
            id: props.userId,
            status: state.form.region,
          });
          if (res.status !== 200) return;
          $message.success("修改成功");
        }

        emit("reloadData", state.form);
        state.dialogFormVisible = false;
      });
    };

    return { state, add_submit, props };
  },
};
</script>
