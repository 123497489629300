<style lang="scss" scoped>
.table-header {
  border: 1px solid #ebeef5;
  border-bottom: none;
  height: 50px;
}
.refresh {
  background-color: #f5f5f5;
  width: 50px;
  &:hover .icon_refresh {
    color: rgba(0, 0, 0, 0.6);
  }
}
.icon_refresh {
  color: #999;
  line-height: 50px;
}
.operation {
  white-space: nowrap;
}
</style>
<template>
  <div class="account height_100 flex_tb">
    <!-- 條件刪選 -->
    <collapse @seach="state.seach" />
    <div class="width_100 table-header sizing flex_lr_m">
      <div>
        <i class="el-icon-s-grid pad20" style="color: #999"></i>
        <el-button
          type="primary"
          size="mini"
          @click="() => ((state.dialogVisible = true), (state.name = ''))"
          >新增分類</el-button
        >
      </div>
      <div class="refresh sizing height_100 flex_c_m" @click="refresh">
        <i class="el-icon-refresh cursor fonts-22 icon_refresh"></i>
      </div>
    </div>
    <!-- 表格 -->
    <el-table
      :data="state.tableData"
      height="100%"
      border
      style="width: 100%"
      class="flex1"
      row-key="id"
      lazy
      @selection-change="selection_change"
      :tree-props="{ children: 'children' }"
    >
      <!-- <el-table-column prop="id" label="id" sortable> </el-table-column> -->
      <el-table-column prop="sort_name" label="分類昵稱" min-width="80">
      </el-table-column>
      <el-table-column prop="status" label="狀態">
        <template #default="scope">
          <span>{{ scope.row.status ? "正常" : "禁用" }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="sort_type" label="類型">
        <template #default="scope">
          <span>{{
            scope.row.sort_type === 1
              ? "普通分類"
              : scope.row.sort_type === 2
              ? "必選分類"
              : "自選分類"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="children" label="級別" min-width="80">
        <template #default="scope">
          <el-tag size="small" v-if="scope.row.parent === 0">一級</el-tag>
          <el-tag size="small" v-if="scope.row.parent !== 0" type="success"
            >二級</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="創建時間" min-width="80">
      </el-table-column>
      <el-table-column
        prop="member_name"
        label="操作"
        min-width="180px"
        fixed="right"
      >
        <template #default="scope">
          <div class="operation height_100 flex_c_m" style="flex-wrap: wrap">
            <el-button type="primary" size="mini" @click="editInfo(scope.row)"
              >編輯</el-button
            >
            <el-button type="danger" size="mini" @click="deleteInfo(scope.row)"
              >刪除</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="flex_m_r pain pad20">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="state.user.page"
        :page-sizes="[10, 20, 30, 40, 50]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="Number(state.count)"
      >
      </el-pagination>
    </div>
    <!-- 彈窗 -->
    <pop-up
      :dialogVisible="state.dialogVisible"
      :integral="Number(state.integral)"
      :name="state.name"
      :isEditPass="state.isEditPass"
      :userId="'' + state.userId"
      @toggleDialogVisible="toggleDialogVisible"
      @reloadData="reloadData"
      :isEdit="state.isEdit"
    />
    <!-- 提示框 -->
    <el-dialog title="提示" v-model="state.dialog" width="400px">
      <span>確定{{ state.isEditPass ? "修改" : "刪除" }}嗎？</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="state.dialog = false">取 消</el-button>
          <el-button type="primary" @click="dels_confirm">確 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, getCurrentInstance } from "vue";
import Collapse from "./components/collapse";
import PopUp from "./components/pop-up";

export default {
  components: {
    Collapse,
    PopUp,
  },
  setup() {
    const req_table_data = () => {
      $http.getGoodsSort(state.user).then((res) => {
        state.count = res.data.count;
        res.data.data = Object.values(res.data.data);
        if (res.status !== 200) return;
        state.tableData = res.data.data.map((item) => {
          item.children = Object.values(item.children);
          return item;
        });
      });
    };

    var state = reactive({
      tableData: [],
      user: {
        // 請求參數
        limit: 10, // 條數
        page: 1, // 頁數
        sort_name: "", // 分類名稱
        status: "", // 狀態
      },
      count: "", // 總條數
      seach(data) {
        state.user.sort_name = data.seatch_name;
        state.user.status = data.region;
        req_table_data();
      },
      dialogVisible: false, // 弹窗
      dialog: false, // 提示框
      delAccountId: [], // 批量刪除的id
      delUserId: [],
      integral: "",
      name: "",
      phone: "",
      isEditPass: false,
      userId: "",
      defaultProps: {
        children: "children",
        label: "name",
      },
      isEdit: false, // 是否編輯
    });

    const proxy = getCurrentInstance().appContext.config.globalProperties;
    const { $http, $message } = proxy;

    req_table_data();

    const handleCurrentChange = (val) => {
      state.user.page = val;
      req_table_data();
    };

    const handleSizeChange = (val) => {
      state.user.limit = val;
      req_table_data();
    };

    const refresh = () => {
      req_table_data();
    };

    const toggleDialogVisible = () => {
      state.dialogVisible = false;
      state.isEdit = false;
    };

    const reloadData = (data) => {
      console.log(data);
      req_table_data();
    };

    const selection_change = (selection) => {
      state.delAccountId = selection.map((item) => {
        item = item.id;
        return item;
      });
    };

    const del_accout = () => {
      state.delUserId = [];
      state.dialog = true;
    };

    const dels_confirm = async () => {
      const res = await $http.delGoodsSort({
        id: state.delUserId[0],
      });
      if (res.status !== 200) return;
      req_table_data();
      $message.success("刪除成功");
      state.dialog = false;
    };

    const editInfo = (data) => {
      state.userId = data.id;
      state.name = data.sort_name;
      // state.phone = data.phone;
      // state.integral = data.integral;
      state.isEdit = true;
      state.dialogVisible = true;
    };

    // 刪除用戶
    const deleteInfo = (data) => {
      state.delUserId = [data.id];
      state.dialog = true;
    };

    return {
      state,
      handleCurrentChange,
      handleSizeChange,
      refresh,
      toggleDialogVisible,
      reloadData,
      selection_change,
      del_accout,
      dels_confirm,
      editInfo,
      deleteInfo,
    };
  },
};
</script>
